import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { ObraActions } from "Store/Obra/Obra.actions";
// import { getCurrentTenant } from "Store/Tenant/Tenant.selector";
import { INomenclatureData } from "Data/interfaces/Nomenclatura/INomenclatureData";
import { IObraNomenclaturesRequest } from "Data/interfaces/Obra/IObraNomenclaturas";
import { INomenclatureObraData } from "Data/interfaces/Obra/ICreateOrUpdate";
import NewNomenclaturaForm from "Components/UI/NewNomenclaturaForm";
import { Container, Main, Title } from "./styles";

interface INomenclature {
  csId: number;
  loading?: boolean;
  obraVersionData?: INomenclatureData;
  versionConfigFk?: number;
}

const Nomenclature: FC<INomenclature> = ({
  csId,
  loading,
  obraVersionData,
  versionConfigFk,
}) => {
  const dispatch = useDispatch();
  // const currentTenant = useSelector(getCurrentTenant);

  const [showEdit, setShowEdit] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = (values?: INomenclatureObraData, erros?: string[]) => {
    if (!showEdit || !values || erros) return;

    const request: IObraNomenclaturesRequest = {
      constructionStieId: csId,
      nomenclatures: {
        isVersionControlDefault: values.isVersionControlDefault,
        versionConfig: {
          versionConfigId: versionConfigFk,
          hasRevisionControl: values.VersionConfig.HasRevisionControl,
          versionFields: values.VersionConfig.VersionFields.map(field => ({
            ordem: field.Ordem,
            campoPersonalizado: field.CampoPersonalizado,
            separador: field.Separador,
            campoVersaoId: field.CampoVersaoId,
            // campoVersao: {
            //   campoVersaoId: field.CampoVersaoId,
            //   nomeCampo: field.CampoPersonalizado,
            //   role: field.CampoVersao.Role,
            // },
          })),
          // tenant: {
          //   tenantId: currentTenant?.TenantId,
          //   companyName: currentTenant?.CompanyName,
          //   avatarFileName: currentTenant?.AvatarFileName,
          //   qtdeActiveConstructionSites: 1,
          //   constructionSiteIds: [csId],
          // },
        },
      },
    };
    dispatch(ObraActions.updateNomenclature(request))
  }

  return (
    <Container>
      <Title>Nomenclatura</Title>
      <Main>
        <NewNomenclaturaForm
          isSubmited={isSubmit}
          isEditObra
          obraVersionData={obraVersionData}
          versionConfigFk={versionConfigFk}
          isTabVersion
          showEditObra={showEdit}
          showEditLoading={loading}
          onShow={() => setShowEdit(true)}
          onCancel={() => setShowEdit(false)}
          onSubmit={handleSubmit}
        />
      </Main>
    </Container>
  )
}

export default Nomenclature;
