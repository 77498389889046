import { FC, useEffect, useState } from "react";
import UploadAvatar from "Components/UI/UploadAvatar";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Icon } from "Components/UI";
import Form from "Components/UI/Form/Form";
import schema from "./details.schema";
import Field from "Components/UI/Field";
import Button from "Components/UI/Button";
import AvisoArquivarObra from "Pages/Obras/Modals/AvisoArquivarObra";
import { useDispatch } from "react-redux";
import { ObraActions } from "Store/Obra/Obra.actions";
import toastHandler from 'Utils/toastHandler';
import { IArchivedStatusRequest } from "Data/interfaces/Obra/IArchivedStatusRequest";
import { BtnContainer, Container, Divider, Main, Title } from "./styles";
import { loadImage } from "Utils/MultidomailUtils";

interface IDetails {
  csId: number;
  Name?: string;
  Type?: string;
  Address?: string;
  AvatarFileName?: string;
  OriginalImageUrl?: string;
  loading?: boolean;
}

const Details: FC<IDetails> = ({
  csId,
  Name,
  Type,
  Address,
  AvatarFileName,
  OriginalImageUrl,
  loading,
}) => {
  const dispatch = useDispatch();

  const [sentRequest, setSentRequest] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [imageConstruction, setImageConstruction] = useState(AvatarFileName);
  const [showEdit, setShowEdit] = useState(false);
  const [showArchiveObra, setShowArchiveObra] = useState(false);
  const [fileImg, setFileImg] = useState<File | null>(null);
  const [nameVal, setNameVal] = useState(Name);
  const [typeVal, setTypeVal] = useState(Type);
  const [addressVal, setAddressVal] = useState(Address);

  const handleSubmit = (values?: any, errors?: string[]) => {
    if (!showEdit) return;

    if (errors) {
      errors.forEach((error) => {
        toastHandler.handler({ description: error, icon: 'error', title: '' });
      });
      return false;
    } else {
      if (values) {
        dispatch(ObraActions.updateDetails({
          constructionStieId: csId,
          ...values,
          AvatarFileName: imageConstruction,
        }));
        setSentRequest(true);
      }
    }

    setIsSubmited(false);
  }

  const confirmEdit = () => {
    setIsSubmited(true);
  }

  const cancelEdit = () => {
    setNameVal(Name);
    setTypeVal(Type);
    setAddressVal(Address);
    setFileImg(null);
    setImageConstruction(AvatarFileName);
    setShowEdit(false);
  }

  const onUploadImageConstruction = (uploadedImage: string) => {
    setImageConstruction(uploadedImage);
  };

  const handleFileImg = (fileImg: File) => {
    setFileImg(fileImg);
  };

  const arquivarObra = () => {
    const status: IArchivedStatusRequest = {
      ConstructionSiteId: csId,
      isArchived: true,
    };
    dispatch(ObraActions.updateArchivedStatus(status));
  };

  useEffect(() => {
    setImageConstruction(AvatarFileName);
    setNameVal(Name);
    setTypeVal(Type);
    setAddressVal(Address);
  }, [Name, AvatarFileName]);

  useEffect(() => {
    if (sentRequest && !loading) {
      setSentRequest(false);
      cancelEdit();
    }
  }, [sentRequest, loading]);

  return (
    <>
      <Container>
        <Title>Dados da Obra</Title>
        <Main
          img={loadImage(
            fileImg ||
            `${process.env.REACT_APP_IMAGE_STORAGE_URL?.replace('hom', 'prod')}${imageConstruction}`,
            false
          )}
        >
          <UploadAvatar
            isDisabled={!showEdit}
            onUploadImage={onUploadImageConstruction}
            onFileImage={handleFileImg}
          >
            {(isLoading: boolean) => {
              return (
                <div className="changeImageWrapper">
                  {isLoading && (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          rev=""
                          color="white"
                          style={{ fontSize: 40, color: 'white' }}
                        />
                      }
                    />
                  )}
                  {!isLoading && showEdit && (
                    <>
                      <Icon color="branco" customSize={19} icon="editarImg" />
                      <span className="changeImageLabel">Alterar imagem</span>
                    </>
                  )}
                </div>
              );
            }}
          </UploadAvatar>
          <div className="formWrapper">
            <Form
              schema={schema}
              onSubmit={handleSubmit}
              isSubmited={isSubmited}
              initialValues={{
                Name: nameVal,
                Type: typeVal,
                Address: addressVal,
              }}
            >
              <div className="formWrapperTop">
                <span className="containerName">
                  <span className="fieldLabel">Nome da Obra</span>
                  <Field
                    name='Name'
                    label=''
                    value={nameVal}
                    onInput={setNameVal}
                    disabled={!showEdit}
                    className="fieldName"
                  />
                </span>
                <span className="containerType">
                  <span className="fieldLabel">Tipo</span>
                  <Field
                    name='Type'
                    label=''
                    value={typeVal}
                    onInput={setTypeVal}
                    disabled={!showEdit}
                    className="fieldType"
                  />
                </span>
              </div>
              <span className="containerAdrress">
                <span className="fieldLabel">Endereço</span>
                <Field
                  name='Address'
                  label=''
                  value={addressVal}
                  onInput={setAddressVal}
                  disabled={!showEdit}
                  className="fieldAdrress"
                />
              </span>
            </Form>
          </div>
        </Main>
        <Divider />
        <BtnContainer>
          {!showEdit && (
            <Button type="text" onClick={() => setShowEdit(true)}>
              Editar dados
            </Button>
          )}
          {showEdit && (
            <>
              <Button
                type="primary"
                className="saveBtn"
                onClick={loading ? undefined : confirmEdit}
              >
                {loading && (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        rev=""
                        color="white"
                        style={{ fontSize: 12, color: 'white', marginRight: 6 }}
                      />
                    }
                  />
                )}
                {loading ? 'Salvando...' : 'Salvar alterações'}
              </Button>
              <Button type="text" className="cancelBtn" onClick={cancelEdit}>
                Cancelar
              </Button>
            </>
          )}
        </BtnContainer>
      </Container>

      <Container marginTop={16}>
        <Title>Arquivar obra</Title>
        <Main direction="column">
          <span className="danger">
            Cuidado! Ao arquivar uma obra ela fica indisponível para todos os participantes da obra.
          </span>
          <div className="footer">
            <Button
              type="text"
              className="archiveBtn"
              onClick={() => setShowArchiveObra(true)}
            >
              <Icon icon="archive" customSize={16} className="archiveIcon" />
              Arquivar obra
            </Button>
          </div>
        </Main>
      </Container>

      {showArchiveObra && (
        <AvisoArquivarObra
          nomeObra={Name}
          visible={showArchiveObra}
          onCancel={() => setShowArchiveObra(false)}
          onConfirm={arquivarObra} />
      )}
    </>
  )
}

export default Details;
