import { FC, useEffect, useMemo, useState } from "react";
import { Icon } from "Components/UI";
import Form from "Components/UI/Form/Form";
import schema from "./users.schema";
import Field from "Components/UI/Field";
import Button from "Components/UI/Button";
import ItemInvited from "./components/ItemInvited";
import ItemShared from "./components/ItemShared";
import Select from "Components/UI/Select";
import InfoTooltip from "Components/UI/InfoTooltip";
import SearchInput from "Components/UI/SearchInput";
import LoadingUsersPermissions from "Components/UI/CustomLoading/LoadingUsersPermissions";
import {
  InputUserProps,
  InvitedUserHashMap,
  IRole,
  IUsers,
  SharedUserHashMap,
} from "./user.model";
import {
  Container,
  Content,
  Description,
  FormContainer,
  Main,
  Title,
} from "./styles";
import CallToAction from "./components/CallToAction";
import { useDispatch } from "react-redux";
import { ObraActions } from "Store/Obra/Obra.actions";
import { IObraInvitedUsersDeleteRequest, IObraInvitedUsersRequest, IObraUsersRequest } from "Data/interfaces/Obra/IObraUsers";
import { ISharedUser } from "Data/interfaces/User/ISharedUser";

const Users: FC<IUsers> = ({
  csId,
  tenantRoles,
  tenantSharedUsers,
  sharedUsers,
  invitedUsers,
  currentRole,
  numberInvitedAllUsers,
  firstInvitedUsers,
  loading,
  userInfo,
}) => {
  const dispatch = useDispatch();

  const [InvitedUsers, setInvitedUsers] = useState<InvitedUserHashMap>({});
  const [SharedUsers, setSharedUsers] = useState<SharedUserHashMap>({});
  const [RoleNewUser, setRoleNewUser] = useState<any>(4);
  const [searchUsers, setSearchUsers] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [infoUsuarioUpgradeVisible, setInfoUsuarioUpgradeVisible] = useState(false);
  const [newInviteds, setNewInviteds] = useState(0);
  const maxUsers = userInfo?.UserStoreItem.MaxQtyUsers
    || userInfo?.UserStoreItem.StorePlan?.MaxQtyUsers;

  const roles: IRole[] = tenantRoles.map((role) => ({
    value: role.TenantRoleId,
    description: role.RoleName,
    label: role.RoleName
  }));

  const filteredInvitedUsers = useMemo(() => {
    const data = Object.values(InvitedUsers);

    if (searchUsers && data && data?.length > 0) {
      return data.filter((user: any) => {
        return user.Email?.toLowerCase().includes(searchUsers.toLowerCase());
      });
    }

    return data;
  }, [InvitedUsers, searchUsers]);

  const filteredSharedUsers = useMemo(() => {
    const data = Object.values(SharedUsers);

    if (searchUsers && data && data?.length > 0) {
      return data.filter((user: any) => {
        return user.User?.Nome?.toLowerCase().includes(searchUsers.toLowerCase()) ||
          user.Email?.toLowerCase().includes(searchUsers.toLowerCase());
      });
    }

    return data;
  }, [SharedUsers, searchUsers]);

  const getPrivilegesByRole = (roleId: number) => {
    const roleName = tenantRoles.find(role => role.TenantRoleId === roleId)?.RoleName;

    switch (roleName) {
      case 'Coordenador':
        return [0, 1, 2, 3, 4];
      case 'Engenheiro':
        return [0, 1, 2, 4];
      case 'Projetista':
        return [0, 1, 2];
      case 'Consultor':
        return [0];
    }
  }

  const addUser = ({ Email }: InputUserProps) => {
    const request: IObraInvitedUsersRequest = {
      constructionStieId: csId,
      email: Email,
      roleId: RoleNewUser,
    };
    dispatch(ObraActions.updateInvitedUsers(request));
  }

  const removeUser = (email: string) => {
    const request: IObraInvitedUsersDeleteRequest = {
      constructionStieId: csId,
      invitedEmail: email,
    };
    dispatch(ObraActions.deleteInvitedUsers(request));
  }

  const share = (user: ISharedUser) => {
    const request: IObraUsersRequest = {
      constructionStieId: csId,
      userId: user.UserFk || user.UserId || '',
    };
    dispatch(ObraActions.updateUsers(request));
  }

  const unshare = (user: ISharedUser) => {
    const request: IObraUsersRequest = {
      constructionStieId: csId,
      userId: user.UserFk || user.UserId || '',
    };
    dispatch(ObraActions.deleteUsers(request));
  }

  useEffect(() => {
    const populatedUser = () => {
      if (invitedUsers?.length > 0) {
        setInvitedUsers(() => {
          const prev: InvitedUserHashMap = {};
          for (const user of invitedUsers) {
            prev[user.Email] = user;
          }

          return { ...prev };
        });
      } else {
        setInvitedUsers({});
      }

      if (tenantSharedUsers?.length > 0) {
        setSharedUsers(() => {
          const prev: SharedUserHashMap = {};
          for (const user of tenantSharedUsers) {
            if (user.Id !== userInfo?.Id) {
              const userIsSharedIndex = sharedUsers?.findIndex(sharedUser => {
                return sharedUser.UserFk === user.Id;
              });

              if (userIsSharedIndex !== -1) {
                const userIsShared = sharedUsers[userIsSharedIndex];
                userIsShared.isShared = true;
                if (!userIsShared?.User) userIsShared.User = user;
                prev[userIsShared.User?.Email || ''] = userIsShared;

              } else {
                const privileges = getPrivilegesByRole(user.CurrentRoleFk ?? 0) ?? [];
                const hasPlottingPermission = privileges.indexOf(4) > -1;

                if (hasPlottingPermission) privileges.splice(privileges.indexOf(4), 1);

                prev[user.Email || ''] = {
                  User: user,
                  HasPlottingPermission: hasPlottingPermission,
                  isShared: false,
                  UserFk: user.Id,
                  UserId: user.Id,
                  Privileges: privileges,
                }
              }
            }
          }

          return { ...prev };
        });
      } else {
        setSharedUsers({});
      }
    }

    populatedUser();
  }, [invitedUsers, sharedUsers, tenantSharedUsers, userInfo, loading]);

  useEffect(() => {
    if (filteredInvitedUsers && firstInvitedUsers) {
      setNewInviteds(filteredInvitedUsers.filter((user) => {
        return !firstInvitedUsers.map(userAll => userAll.Email).includes(user.Email);
      })?.length);
    }
    if (filteredInvitedUsers && !firstInvitedUsers) {
      setNewInviteds(filteredInvitedUsers.length);
    }
  }, [filteredInvitedUsers, firstInvitedUsers]);

  useEffect(() => {
    console.log({numberInvitedAllUsers, newInviteds, maxUsers});
    if (maxUsers) {
      if (numberInvitedAllUsers && ((numberInvitedAllUsers + newInviteds) >= maxUsers)) {
        setInfoUsuarioUpgradeVisible(true);
      } else {
        setInfoUsuarioUpgradeVisible(false)
      }
    }
  }, [SharedUsers, InvitedUsers, newInviteds, numberInvitedAllUsers]);

  return (
    <Container>
      <Title>Usuários</Title>

      {infoUsuarioUpgradeVisible && <CallToAction userInfo={userInfo} />}

      <FormContainer>
        <Description>
          Convide um usuário para a obra
        </Description>
        <Form schema={schema} onSubmit={addUser} resetForm={true}>
          <div className="formWrapper">
            <Field
              name="Email"
              label=""
              placeholder="Email usuário"
              height="36px"
              disabled={infoUsuarioUpgradeVisible}
              className="fieldName"
            />
            <Select
              onSelect={setRoleNewUser}
              placeholder="Selecione um papel"
              defaultValue={RoleNewUser}
              options={roles}
              disabled={infoUsuarioUpgradeVisible}
              className="selectRole"
            />
            <Button type="primary" htmlType="submit" className="formBtn">
              Convidar
            </Button>
          </div>
        </Form>
        <div className="infoContainer">
          <span className="infoText">Precisa de ajuda com os papéis?</span>
          <InfoTooltip
            placement="bottom"
            overlay={
              <div>
                Para conhecer os papéis acesse Gerenciar usuários &gt; Papéis e permissões
                no menu lateral
              </div>
            }
          >
            <Icon
              icon="informacao"
              customSize={12}
              className="infoIcon"
            />
          </InfoTooltip>
        </div>
      </FormContainer>

      <Main>
        <Description>
          Selecione os usuários que deseja compartilhar a obra
        </Description>

        <div className="searchContainer">
          <SearchInput
            setSearch={setSearchUsers}
            setSearchLoading={setSearchLoading}
            inputPlaceholder="Pesquise por um usuário"
          />
        </div>

        <Content>
          {searchLoading && (
            <LoadingUsersPermissions multiple={6} />
          )}
          {
            searchUsers &&
            filteredInvitedUsers.length === 0 &&
            filteredSharedUsers.length === 0 && (
              <div className="emptyContainer">
                <span className="emptyTitle">
                  Nenhum usuário encontrado
                </span>
                <span className="emptyText">
                  Tente pesquisar pelo e-mail ou nome do usuário.
                </span>
              </div>
            )}
          {!searchLoading && filteredInvitedUsers.length > 0 && (
            filteredInvitedUsers.map(user => (
              <ItemInvited
                key={user.Email}
                user={user}
                search={searchUsers}
                roles={roles}
                loading={loading}
                onRemove={removeUser}
              />
            ))
          )}
          {!searchLoading && filteredSharedUsers.length > 0 && (
            filteredSharedUsers.map(user => (
              <ItemShared
                key={user.UserFk}
                user={user}
                search={searchUsers}
                roles={roles}
                loading={loading}
                onShare={share}
                onUnshare={unshare}
              />
            ))
          )}
        </Content>
      </Main>

    </Container>
  )
}

export default Users;
