import { ActionsUnion, Action, createAction } from '../../Utils/actionHelper';
import { IObraListResponse, IObraResponse } from '../../Data/interfaces/Obra/IObraListResponse';
import { IArchivedStatusRequest } from '../../Data/interfaces/Obra/IArchivedStatusRequest';
import { ICreateOrUpdate } from '../../Data/interfaces/Obra/ICreateOrUpdate';
import { IDisciplineData, IObraData } from '../../Data/interfaces/Obra/IObraData';
import { IUsersConstructionSiteResponse } from '../../Data/interfaces/User/IUsersConstructionSiteResponse';
import IBindUsersConstructionSiteRequest from '../../Data/interfaces/User/IBindUsersConstructionSiteRequest';
import { IAccountInfo } from '../../Data/interfaces/Obra/IAccountInfo';
import { IUpdateConstructionSiteApiRequest } from '../../Data/interfaces/Obra/IUpdateConstructionSiteApiRequest';
import { IStorageData } from '../../Data/interfaces/Obra/IStorageData';
import { IUpdateDisciplineStatusRequest } from 'Data/interfaces/Obra/IUpdateDisciplineStatusRequest';
import { IInvitedUser } from '../../Data/interfaces/User/IInvitedUser';
import { IMatchParams } from 'Data/interfaces/Obra/IMatchParams';
import { IObraDetailsRequest, IObraDetailsResponse } from 'Data/interfaces/Obra/IObraDetails';
import { IObraDisciplinesRequest, IObraDisciplinesResponse } from 'Data/interfaces/Obra/IObraDisciplines';
import { IObraDeleteUsersResponse, IObraInvitedUsersDeleteRequest, IObraInvitedUsersDeleteResponse, IObraInvitedUsersRequest, IObraInvitedUsersResponse, IObraUsersRequest, IObraUsersResponse } from 'Data/interfaces/Obra/IObraUsers';
import { IObraPermissionsRequest, IObraPermissionsResponse } from 'Data/interfaces/Obra/IObraPermissions';
import { IObraNomenclaturesRequest, IObraNomenclaturesResponse } from 'Data/interfaces/Obra/IObraNomenclaturas';
import { ICreateStageRequest, ICreateStageResponse, IDeleteStageRequest, IDeleteStageResponse, IListStagesResponse, IUpdateStageRequest, IUpdateStageResponse } from 'Data/interfaces/Obra/IObraStages';

export enum ObraActionKeys {
  LIST_OBRA_REQUEST = 'LIST_OBRA_REQUEST',
  LIST_OBRA_SUCCESS = 'LIST_OBRA_SUCCESS',
  LIST_OBRA_FAILED = 'LIST_OBRA_FAILED',

  GET_OBRA_REQUEST = 'GET_OBRA_REQUEST',
  GET_OBRA_SUCCESS = 'GET_OBRA_SUCCESS',
  GET_OBRA_FAILED = 'GET_OBRA_FAILED',

  CREATE_OR_UPDATE_OBRA_REQUEST = 'CREATE_OR_UPDATE_OBRA_REQUEST',
  CREATE_OR_UPDATE_OBRA_SUCCESS = 'CREATE_OR_UPDATE_OBRA_SUCCESS',
  CREATE_OR_UPDATE_OBRA_FAILED = 'CREATE_OR_UPDATE_OBRA_FAILED',

  ADD_OBRA_CHUNCK_DATA = 'ADD_OBRA_CHUNCK_DATA',

  UPDATE_ARCHIVED_STATUS_REQUEST = 'UPDATE_ARCHIVED_STATUS_REQUEST',
  UPDATE_ARCHIVED_STATUS_SUCCESS = 'UPDATE_ARCHIVED_STATUS_SUCCESS',
  UPDATE_ARCHIVED_STATUS_FAILED = 'UPDATE_ARCHIVED_STATUS_FAILED',

  GET_DISCIPLINES_REQUEST = 'GET_DISCIPLINES_REQUEST',
  GET_DISCIPLINES_SUCCESS = 'GET_DISCIPLINES_SUCCESS',
  GET_DISCIPLINES_FAILED = 'GET_DISCIPLINES_FAILED',

  GET_USERS_CONSTRUCTION_SITE_REQUEST = 'GET_USERS_CONSTRUCTION_SITE_REQUEST',
  GET_USERS_CONSTRUCTION_SITE_SUCCESS = 'GET_USERS_CONSTRUCTION_SITE_SUCCESS',
  GET_USERS_CONSTRUCTION_SITE_FAILED = 'GET_USERS_CONSTRUCTION_SITE_FAILED',

  BIND_USERS_CONSTRUCTION_SITE_REQUEST = 'BIND_USERS_CONSTRUCTION_SITE_REQUEST',
  BIND_USERS_CONSTRUCTION_SITE_SUCCESS = 'BIND_USERS_CONSTRUCTION_SITE_SUCCESS',
  BIND_USERS_CONSTRUCTION_SITE_FAILED = 'BIND_USERS_CONSTRUCTION_SITE_FAILED',

  LIST_CONSTRUCTION_SITE_ACCOUNTS_REQUEST = 'LIST_CONSTRUCTION_SITE_ACCOUNTS_REQUEST',
  LIST_CONSTRUCTION_SITE_ACCOUNTS_SUCCESS = 'LIST_CONSTRUCTION_SITE_ACCOUNTS_SUCCESS',
  LIST_CONSTRUCTION_SITE_ACCOUNTS_FAILED = 'LIST_CONSTRUCTION_SITE_ACCOUNTS_FAILED',

  UPDATE_CONSTRUCTION_SITE_API_REQUEST = 'UPDATE_CONSTRUCTION_SITE_API_REQUEST',
  UPDATE_CONSTRUCTION_SITE_API_SUCCESS = 'UPDATE_CONSTRUCTION_SITE_API_SUCCESS',
  UPDATE_CONSTRUCTION_SITE_API_FAILED = 'UPDATE_CONSTRUCTION_SITE_API_FAILED',

  LIST_CONSTRUCTION_SITES_STORAGE_REQUEST = 'LIST_CONSTRUCTION_SITES_STORAGE_REQUEST',
  LIST_CONSTRUCTION_SITES_STORAGE_SUCCESS = 'LIST_CONSTRUCTION_SITES_STORAGE_SUCCESS',
  LIST_CONSTRUCTION_SITES_STORAGE_FAILED = 'LIST_CONSTRUCTION_SITES_STORAGE_FAILED',

  GET_ALL_CONSTRUCTIONS_SITES_REQUEST = 'GET_ALL_CONSTRUCTIONS_SITES_REQUEST',
  GET_ALL_CONSTRUCTIONS_SITES_SUCCESS = 'GET_ALL_CONSTRUCTIONS_SITES_SUCCESS',
  GET_ALL_CONSTRUCTIONS_SITES_FAILURE = 'GET_ALL_CONSTRUCTIONS_SITES_FAILURE',

  CLEAR_ALL_CONSTRUCTIONS_SITES = 'CLEAR_ALL_CONSTRUCTIONS_SITES',

  OBRA_RESET_STATE = 'OBRA_RESET_STATE',

  OBRA_USERS_LIMIT_INVITED = 'OBRA_USERS_LIMIT_INVITED',

  TOGGLE_COLLAPSED = 'TOGGLE_COLLAPSED',

  STORE_ARCHIVES_PATH = 'STORE_ARCHIVES_PATH',
  STORE_PLOTAGENS_PATH = 'STORE_PLOTAGENS_PATH',

  CLEAR_CONSTRUCTION_SITE_SEARCH = 'CLEAR_CONSTRUCTION_SITE_SEARCH',

  UPDATE_DETAILS_REQUEST = 'UPDATE_DETAILS_REQUEST',
  UPDATE_DETAILS_SUCCESS = 'UPDATE_DETAILS_SUCCESS',
  UPDATE_DETAILS_FAILED = 'UPDATE_DETAILS_FAILED',

  UPDATE_DISCIPLINES_REQUEST = 'UPDATE_DISCIPLINES_REQUEST',
  UPDATE_DISCIPLINES_SUCCESS = 'UPDATE_DISCIPLINES_SUCCESS',
  UPDATE_DISCIPLINES_FAILED = 'UPDATE_DISCIPLINES_FAILED',

  UPDATE_USERS_REQUEST = 'UPDATE_USERS_REQUEST',
  UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS',
  UPDATE_USERS_FAILED = 'UPDATE_USERS_FAILED',

  DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST',
  DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS',
  DELETE_USERS_FAILED = 'DELETE_USERS_FAILED',

  UPDATE_INVITED_USERS_REQUEST = 'UPDATE_INVITED_USERS_REQUEST',
  UPDATE_INVITED_USERS_SUCCESS = 'UPDATE_INVITED_USERS_SUCCESS',
  UPDATE_INVITED_USERS_FAILED = 'UPDATE_INVITED_USERS_FAILED',

  DELETE_INVITED_USERS_REQUEST = 'DELETE_INVITED_USERS_REQUEST',
  DELETE_INVITED_USERS_SUCCESS = 'DELETE_INVITED_USERS_SUCCESS',
  DELETE_INVITED_USERS_FAILED = 'DELETE_INVITED_USERS_FAILED',

  UPDATE_PERMISSIONS_REQUEST = 'UPDATE_PERMISSIONS_REQUEST',
  UPDATE_PERMISSIONS_SUCCESS = 'UPDATE_PERMISSIONS_SUCCESS',
  UPDATE_PERMISSIONS_FAILED = 'UPDATE_PERMISSIONS_FAILED',

  UPDATE_NOMENCLATURE_REQUEST = 'UPDATE_NOMENCLATURE_REQUEST',
  UPDATE_NOMENCLATURE_SUCCESS = 'UPDATE_NOMENCLATURE_SUCCESS',
  UPDATE_NOMENCLATURE_FAILED = 'UPDATE_NOMENCLATURE_FAILED',

  LIST_STAGES_REQUEST = 'LIST_STAGES_REQUEST',
  LIST_STAGES_SUCCESS = 'LIST_STAGES_SUCCESS',
  LIST_STAGES_FAILED = 'LIST_STAGES_FAILED',

  CREATE_STAGES_REQUEST = 'CREATE_STAGES_REQUEST',
  CREATE_STAGES_SUCCESS = 'CREATE_STAGES_SUCCESS',
  CREATE_STAGES_FAILED = 'CREATE_STAGES_FAILED',

  UPDATE_STAGES_REQUEST = 'UPDATE_STAGES_REQUEST',
  UPDATE_STAGES_SUCCESS = 'UPDATE_STAGES_SUCCESS',
  UPDATE_STAGES_FAILED = 'UPDATE_STAGES_FAILED',

  DELETE_STAGES_REQUEST = 'DELETE_STAGES_REQUEST',
  DELETE_STAGES_SUCCESS = 'DELETE_STAGES_SUCCESS',
  DELETE_STAGES_FAILED = 'DELETE_STAGES_FAILED',
}

export const ObraActions = {
  list: (): ListAction =>
    createAction(ObraActionKeys.LIST_OBRA_REQUEST),
  listSuccess: (obraList: IObraListResponse): ListSuccessAction =>
    createAction(ObraActionKeys.LIST_OBRA_SUCCESS, obraList),
  listFailure: (err: string): ListFailureAction =>
    createAction(ObraActionKeys.LIST_OBRA_FAILED, err),

  getObra: (request: IUpdateDisciplineStatusRequest): GetObraAction =>
    createAction(ObraActionKeys.GET_OBRA_REQUEST, request),
  getObraSuccess: (obra: IObraResponse): GetObraSuccessAction =>
    createAction(ObraActionKeys.GET_OBRA_SUCCESS, obra),
  getObraFailure: (err: string): GetObraFailureAction =>
    createAction(ObraActionKeys.GET_OBRA_FAILED, err),

  createOrUpdate: (createOrUpdate: ICreateOrUpdate): CreateAction =>
    createAction(ObraActionKeys.CREATE_OR_UPDATE_OBRA_REQUEST, createOrUpdate),
  createOrUpdateSuccess: (response: IObraResponse): CreateSuccessAction =>
    createAction(ObraActionKeys.CREATE_OR_UPDATE_OBRA_SUCCESS, response),
  createOrUpdateFailure: (err: string): CreateFailureAction =>
    createAction(ObraActionKeys.CREATE_OR_UPDATE_OBRA_FAILED, err),

  addObraChunckData: (createOrUpdateData: ICreateOrUpdate): AddObraChunckData =>
    createAction(ObraActionKeys.ADD_OBRA_CHUNCK_DATA, createOrUpdateData),

  updateArchivedStatus: (status: IArchivedStatusRequest): UpdateArchivedStatusAction =>
    createAction(ObraActionKeys.UPDATE_ARCHIVED_STATUS_REQUEST, status),
  updateArchivedStatusSuccess: (constructionUpdated: IArchivedStatusRequest): UpdateArchivedStatusSuccessAction =>
    createAction(ObraActionKeys.UPDATE_ARCHIVED_STATUS_SUCCESS, constructionUpdated),
  updateArchivedStatusFailure: (err: string): UpdateArchivedStatusFailureAction =>
    createAction(ObraActionKeys.UPDATE_ARCHIVED_STATUS_FAILED, err),

  getDisciplines: (): GetDisciplinesAction =>
    createAction(ObraActionKeys.GET_DISCIPLINES_REQUEST),
  getDisciplinesSuccess: (disciplines: IDisciplineData[]): GetDisciplinesSuccessAction =>
    createAction(ObraActionKeys.GET_DISCIPLINES_SUCCESS, disciplines),
  getDisciplinesFailure: (err: string): GetDisciplinesFailureAction =>
    createAction(ObraActionKeys.GET_DISCIPLINES_FAILED, err),

  getUsersConstructionSite: (constructionSiteId: number): GetUsersConstructionSiteAction =>
    createAction(ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_REQUEST, constructionSiteId),
  getUsersConstructionSiteSuccess: (users: IUsersConstructionSiteResponse): GetUsersConstructionSiteSuccessAction =>
    createAction(ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_SUCCESS, users),
  getUsersConstructionSiteFailure: (err: string): GetUsersConstructionSiteFailureAction =>
    createAction(ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_FAILED, err),

  bindUsersConstructionSite: (users: IBindUsersConstructionSiteRequest): BindUsersConstructionSiteAction =>
    createAction(ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_REQUEST, users),
  bindUsersConstructionSiteSuccess: (users: IBindUsersConstructionSiteRequest):
    BindUsersConstructionSiteSuccessAction => createAction(ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_SUCCESS, users),
  bindUsersConstructionSiteFailure: (err: string): BindUsersConstructionSiteFailureAction =>
    createAction(ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_FAILED, err),

  listConstructionSiteAccounts: (): ListConstructionSiteAccountsAction =>
    createAction(ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_REQUEST),
  listConstructionSiteAccountsSuccess: (listAccounts: IAccountInfo[]):
    ListConstructionSiteAccountsSuccessAction =>
    createAction(ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_SUCCESS, listAccounts),
  listConstructionSiteAccountsFailure: (err: string): ListConstructionSiteAccountsFailureAction =>
    createAction(ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_FAILED, err),

  updateConstructionSiteApi: (request: IUpdateConstructionSiteApiRequest):
    UpdateConstructionSiteApiAction => createAction(ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_REQUEST, request),
  updateConstructionSiteApiSuccess: (response: any): UpdateConstructionSiteApiSuccessAction =>
    createAction(ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_SUCCESS, response),
  updateConstructionSiteApiFailure: (err: string): UpdateConstructionSiteApiFailureAction =>
    createAction(ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_FAILED, err),

  listConstructionSitesStorage: (): ListConstructionSitesStorageAction =>
    createAction(ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_REQUEST),
  listConstructionSitesStorageSuccess: (listStorage: IStorageData[]):
    ListConstructionSitesStorageSuccessAction =>
    createAction(ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_SUCCESS, listStorage),
  listConstructionSitesStorageFailure: (err: string): ListConstructionSitesStorageFailureAction =>
    createAction(ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_FAILED, err),

  listAllConstructionsSitesOfUser: (): ListAllConstructionsSitesOfUser =>
    createAction(ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_REQUEST),
  listAllConstructionsSitesOfUserSuccess: (constructoinsSitesResponse: IObraData[]): ListAllConstructionsSitesOfUserSuccess =>
    createAction(ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_SUCCESS, constructoinsSitesResponse),
  listAllConstructionsSitesOfUserFailure: (error: string): ListAllConstructionsSitesOfUserFailed =>
    createAction(ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_FAILURE, error),

  clearAllConstructionsSites: (): ClearAllConstructionsSites =>
    createAction(ObraActionKeys.CLEAR_ALL_CONSTRUCTIONS_SITES),

  resetState: (): ResetStateAction =>
    createAction(ObraActionKeys.OBRA_RESET_STATE),

  usersForLimitInvited: (users: IInvitedUser[]): UsersForLimitInvitedAction =>
    createAction(ObraActionKeys.OBRA_USERS_LIMIT_INVITED, users),

  toggleCollapse: (toggle: boolean): ToggleCollapsedAction =>
    createAction(ObraActionKeys.TOGGLE_COLLAPSED, toggle),

  storeArchivesPath: (match: IMatchParams): StoreArchivesPathAction =>
    createAction(ObraActionKeys.STORE_ARCHIVES_PATH, match),
  storePlotagensPath: (match: IMatchParams): StorePlotagensPathAction =>
    createAction(ObraActionKeys.STORE_PLOTAGENS_PATH, match),

  clearConstructionSiteSearch: (clear: boolean): ClearConstructionSiteSearchAction =>
    createAction(ObraActionKeys.CLEAR_CONSTRUCTION_SITE_SEARCH, clear),

  updateDetails: (request: IObraDetailsRequest): UpdateDetailsAction =>
    createAction(ObraActionKeys.UPDATE_DETAILS_REQUEST, request),
  updateDetailsSuccess: (resposne: IObraDetailsResponse): UpdateDetailsSuccessAction =>
    createAction(ObraActionKeys.UPDATE_DETAILS_SUCCESS, resposne),
  updateDetailsFailure: (err: string): UpdateDetailsFailureAction =>
    createAction(ObraActionKeys.UPDATE_DETAILS_FAILED, err),

  updateDisciplines: (request: IObraDisciplinesRequest): UpdateDisciplinesAction =>
    createAction(ObraActionKeys.UPDATE_DISCIPLINES_REQUEST, request),
  updateDisciplinesSuccess: (response: IObraDisciplinesResponse): UpdateDisciplinesSuccessAction =>
    createAction(ObraActionKeys.UPDATE_DISCIPLINES_SUCCESS, response),
  updateDisciplinesFailure: (err: string): UpdateDisciplinesFailureAction =>
    createAction(ObraActionKeys.UPDATE_DISCIPLINES_FAILED, err),

  updateUsers: (request: IObraUsersRequest): UpdateUsersAction =>
    createAction(ObraActionKeys.UPDATE_USERS_REQUEST, request),
  updateUsersSuccess: (response: IObraUsersResponse): UpdateUsersSuccessAction =>
    createAction(ObraActionKeys.UPDATE_USERS_SUCCESS, response),
  updateUsersFailure: (err: string): UpdateUsersFailureAction =>
    createAction(ObraActionKeys.UPDATE_USERS_FAILED, err),

  deleteUsers: (request: IObraUsersRequest): DeleteUsersAction =>
    createAction(ObraActionKeys.DELETE_USERS_REQUEST, request),
  deleteUsersSuccess: (response: IObraDeleteUsersResponse): DeleteUsersSuccessAction =>
    createAction(ObraActionKeys.DELETE_USERS_SUCCESS, response),
  deleteUsersFailure: (err: string): DeleteUsersFailureAction =>
    createAction(ObraActionKeys.DELETE_USERS_FAILED, err),

  updateInvitedUsers: (request: IObraInvitedUsersRequest): UpdateInvitedUsersAction =>
    createAction(ObraActionKeys.UPDATE_INVITED_USERS_REQUEST, request),
  updateInvitedUsersSuccess: (response: IObraInvitedUsersResponse): UpdateInvitedUsersSuccessAction =>
    createAction(ObraActionKeys.UPDATE_INVITED_USERS_SUCCESS, response),
  updateInvitedUsersFailure: (err: string): UpdateInvitedUsersFailureAction =>
    createAction(ObraActionKeys.UPDATE_INVITED_USERS_FAILED, err),

  deleteInvitedUsers: (request: IObraInvitedUsersDeleteRequest): DeleteInvitedUsersAction =>
    createAction(ObraActionKeys.DELETE_INVITED_USERS_REQUEST, request),
  deleteInvitedUsersSuccess: (response: IObraInvitedUsersDeleteResponse): DeleteInvitedUsersSuccessAction =>
    createAction(ObraActionKeys.DELETE_INVITED_USERS_SUCCESS, response),
  deleteInvitedUsersFailure: (err: string): DeleteInvitedUsersFailureAction =>
    createAction(ObraActionKeys.DELETE_INVITED_USERS_FAILED, err),

  updatePermissionsUsers: (request: IObraPermissionsRequest): UpdatePermissionsAction =>
    createAction(ObraActionKeys.UPDATE_PERMISSIONS_REQUEST, request),
  updatePermissionsUsersSuccess: (response: IObraPermissionsResponse): UpdatePermissionsSuccessAction =>
    createAction(ObraActionKeys.UPDATE_PERMISSIONS_SUCCESS, response),
  updatePermissionsUsersFailure: (err: string): UpdatePermissionsFailureAction =>
    createAction(ObraActionKeys.UPDATE_PERMISSIONS_FAILED, err),

  updateNomenclature: (request: IObraNomenclaturesRequest): UpdateNomenclatureAction =>
    createAction(ObraActionKeys.UPDATE_NOMENCLATURE_REQUEST, request),
  updateNomenclatureSuccess: (response: IObraNomenclaturesResponse): UpdateNomenclatureSuccessAction =>
    createAction(ObraActionKeys.UPDATE_NOMENCLATURE_SUCCESS, response),
  updateNomenclatureFailure: (err: string): UpdateNomenclatureFailureAction =>
    createAction(ObraActionKeys.UPDATE_NOMENCLATURE_FAILED, err),

  listStages: (request: number): ListStagesAction =>
    createAction(ObraActionKeys.LIST_STAGES_REQUEST, request),
  listStagesSuccess: (response: IListStagesResponse): ListStagesSuccessAction =>
    createAction(ObraActionKeys.LIST_STAGES_SUCCESS, response),
  listStagesFailure: (err: string): ListStagesFailureAction =>
    createAction(ObraActionKeys.LIST_STAGES_FAILED, err),

  createStages: (request: ICreateStageRequest): CreateStagesAction =>
    createAction(ObraActionKeys.CREATE_STAGES_REQUEST, request),
  createStagesSuccess: (response: ICreateStageResponse): CreateStagesSuccessAction =>
    createAction(ObraActionKeys.CREATE_STAGES_SUCCESS, response),
  createStagesFailure: (err: string): CreateStagesFailureAction =>
    createAction(ObraActionKeys.CREATE_STAGES_FAILED, err),

  updateStages: (request: IUpdateStageRequest): UpdateStagesAction =>
    createAction(ObraActionKeys.UPDATE_STAGES_REQUEST, request),
  updateStagesSuccess: (response: IUpdateStageResponse): UpdateStagesSuccessAction =>
    createAction(ObraActionKeys.UPDATE_STAGES_SUCCESS, response),
  updateStagesFailure: (err: string): UpdateStagesFailureAction =>
    createAction(ObraActionKeys.UPDATE_STAGES_FAILED, err),

  deleteStages: (request: IDeleteStageRequest): DeleteStagesAction =>
    createAction(ObraActionKeys.DELETE_STAGES_REQUEST, request),
  deleteStagesSuccess: (response: IDeleteStageResponse): DeleteStagesSuccessAction =>
    createAction(ObraActionKeys.DELETE_STAGES_SUCCESS, response),
  deleteStagesFailure: (err: string): DeleteStagesFailureAction =>
    createAction(ObraActionKeys.DELETE_STAGES_FAILED, err),
};

export type ObraActionUnion = ActionsUnion<typeof ObraActions>;

export type ListAction = Action<ObraActionKeys.LIST_OBRA_REQUEST>;
export type ListSuccessAction = Action<ObraActionKeys.LIST_OBRA_SUCCESS, IObraListResponse>;
export type ListFailureAction = Action<ObraActionKeys.LIST_OBRA_FAILED, string>;

export type GetObraAction = Action<ObraActionKeys.GET_OBRA_REQUEST, IUpdateDisciplineStatusRequest>;
export type GetObraSuccessAction = Action<ObraActionKeys.GET_OBRA_SUCCESS, IObraResponse>;
export type GetObraFailureAction = Action<ObraActionKeys.GET_OBRA_FAILED, string>;

export type CreateAction = Action<ObraActionKeys.CREATE_OR_UPDATE_OBRA_REQUEST, ICreateOrUpdate>;
export type CreateSuccessAction = Action<ObraActionKeys.CREATE_OR_UPDATE_OBRA_SUCCESS, IObraResponse>;
export type CreateFailureAction = Action<ObraActionKeys.CREATE_OR_UPDATE_OBRA_FAILED, string>;

export type AddObraChunckData = Action<ObraActionKeys.ADD_OBRA_CHUNCK_DATA, ICreateOrUpdate>;

export type UpdateArchivedStatusAction =
  Action<ObraActionKeys.UPDATE_ARCHIVED_STATUS_REQUEST, IArchivedStatusRequest>;
export type UpdateArchivedStatusSuccessAction =
  Action<ObraActionKeys.UPDATE_ARCHIVED_STATUS_SUCCESS, IArchivedStatusRequest>;
export type UpdateArchivedStatusFailureAction =
  Action<ObraActionKeys.UPDATE_ARCHIVED_STATUS_FAILED, string>;

export type GetDisciplinesAction = Action<ObraActionKeys.GET_DISCIPLINES_REQUEST>;
export type GetDisciplinesSuccessAction = Action<ObraActionKeys.GET_DISCIPLINES_SUCCESS, IDisciplineData[]>;
export type GetDisciplinesFailureAction = Action<ObraActionKeys.GET_DISCIPLINES_FAILED, string>;

export type GetUsersConstructionSiteAction = Action<ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_REQUEST, number>;
export type GetUsersConstructionSiteSuccessAction =
  Action<ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_SUCCESS, IUsersConstructionSiteResponse>;
export type GetUsersConstructionSiteFailureAction = Action<ObraActionKeys.GET_USERS_CONSTRUCTION_SITE_FAILED, string>;

export type BindUsersConstructionSiteAction =
  Action<ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_REQUEST, IBindUsersConstructionSiteRequest>;
export type BindUsersConstructionSiteSuccessAction =
  Action<ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_SUCCESS, IBindUsersConstructionSiteRequest>;
export type BindUsersConstructionSiteFailureAction =
  Action<ObraActionKeys.BIND_USERS_CONSTRUCTION_SITE_FAILED, string>;

export type ListConstructionSiteAccountsAction =
  Action<ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_REQUEST>;
export type ListConstructionSiteAccountsSuccessAction =
  Action<ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_SUCCESS, IAccountInfo[]>;
export type ListConstructionSiteAccountsFailureAction =
  Action<ObraActionKeys.LIST_CONSTRUCTION_SITE_ACCOUNTS_FAILED, string>;

export type UpdateConstructionSiteApiAction =
  Action<ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_REQUEST, IUpdateConstructionSiteApiRequest>;
export type UpdateConstructionSiteApiSuccessAction =
  Action<ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_SUCCESS, any>;
export type UpdateConstructionSiteApiFailureAction =
  Action<ObraActionKeys.UPDATE_CONSTRUCTION_SITE_API_FAILED, string>;

export type ListConstructionSitesStorageAction =
  Action<ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_REQUEST>;
export type ListConstructionSitesStorageSuccessAction =
  Action<ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_SUCCESS, IStorageData[]>;
export type ListConstructionSitesStorageFailureAction =
  Action<ObraActionKeys.LIST_CONSTRUCTION_SITES_STORAGE_FAILED, string>;

export type ListAllConstructionsSitesOfUser =
  Action<ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_REQUEST>;

export type ListAllConstructionsSitesOfUserSuccess =
  Action<ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_SUCCESS, IObraData[]>;

export type ListAllConstructionsSitesOfUserFailed =
  Action<ObraActionKeys.GET_ALL_CONSTRUCTIONS_SITES_FAILURE, string>;

export type ClearAllConstructionsSites =
  Action<ObraActionKeys.CLEAR_ALL_CONSTRUCTIONS_SITES>;

export type ResetStateAction = Action<ObraActionKeys.OBRA_RESET_STATE>;

export type UsersForLimitInvitedAction =
  Action<ObraActionKeys.OBRA_USERS_LIMIT_INVITED, IInvitedUser[]>;

export type ToggleCollapsedAction =
  Action<ObraActionKeys.TOGGLE_COLLAPSED, boolean>;

export type StoreArchivesPathAction =
  Action<ObraActionKeys.STORE_ARCHIVES_PATH, IMatchParams>;
export type StorePlotagensPathAction =
  Action<ObraActionKeys.STORE_PLOTAGENS_PATH, IMatchParams>;

export type ClearConstructionSiteSearchAction =
  Action<ObraActionKeys.CLEAR_CONSTRUCTION_SITE_SEARCH, boolean>;

export type UpdateDetailsAction = Action<ObraActionKeys.UPDATE_DETAILS_REQUEST, IObraDetailsRequest>;
export type UpdateDetailsSuccessAction = Action<ObraActionKeys.UPDATE_DETAILS_SUCCESS, IObraDetailsResponse>;
export type UpdateDetailsFailureAction = Action<ObraActionKeys.UPDATE_DETAILS_FAILED, string>;

export type UpdateDisciplinesAction = Action<ObraActionKeys.UPDATE_DISCIPLINES_REQUEST, IObraDisciplinesRequest>;
export type UpdateDisciplinesSuccessAction = Action<ObraActionKeys.UPDATE_DISCIPLINES_SUCCESS, IObraDisciplinesResponse>;
export type UpdateDisciplinesFailureAction = Action<ObraActionKeys.UPDATE_DISCIPLINES_FAILED, string>;

export type UpdateUsersAction = Action<ObraActionKeys.UPDATE_USERS_REQUEST, IObraUsersRequest>;
export type UpdateUsersSuccessAction = Action<ObraActionKeys.UPDATE_USERS_SUCCESS, IObraUsersResponse>;
export type UpdateUsersFailureAction = Action<ObraActionKeys.UPDATE_USERS_FAILED, string>;

export type DeleteUsersAction = Action<ObraActionKeys.DELETE_USERS_REQUEST, IObraUsersRequest>;
export type DeleteUsersSuccessAction = Action<ObraActionKeys.DELETE_USERS_SUCCESS, IObraDeleteUsersResponse>;
export type DeleteUsersFailureAction = Action<ObraActionKeys.DELETE_USERS_FAILED, string>;

export type UpdateInvitedUsersAction = Action<ObraActionKeys.UPDATE_INVITED_USERS_REQUEST, IObraInvitedUsersRequest>;
export type UpdateInvitedUsersSuccessAction = Action<ObraActionKeys.UPDATE_INVITED_USERS_SUCCESS, IObraInvitedUsersResponse>;
export type UpdateInvitedUsersFailureAction = Action<ObraActionKeys.UPDATE_INVITED_USERS_FAILED, string>;

export type DeleteInvitedUsersAction = Action<ObraActionKeys.DELETE_INVITED_USERS_REQUEST, IObraInvitedUsersDeleteRequest>;
export type DeleteInvitedUsersSuccessAction = Action<ObraActionKeys.DELETE_INVITED_USERS_SUCCESS, IObraInvitedUsersDeleteResponse>;
export type DeleteInvitedUsersFailureAction = Action<ObraActionKeys.DELETE_INVITED_USERS_FAILED, string>;

export type UpdatePermissionsAction = Action<ObraActionKeys.UPDATE_PERMISSIONS_REQUEST, IObraPermissionsRequest>;
export type UpdatePermissionsSuccessAction = Action<ObraActionKeys.UPDATE_PERMISSIONS_SUCCESS, IObraPermissionsResponse>;
export type UpdatePermissionsFailureAction = Action<ObraActionKeys.UPDATE_PERMISSIONS_FAILED, string>;

export type UpdateNomenclatureAction = Action<ObraActionKeys.UPDATE_NOMENCLATURE_REQUEST, IObraNomenclaturesRequest>;
export type UpdateNomenclatureSuccessAction = Action<ObraActionKeys.UPDATE_NOMENCLATURE_SUCCESS, IObraNomenclaturesResponse>;
export type UpdateNomenclatureFailureAction = Action<ObraActionKeys.UPDATE_NOMENCLATURE_FAILED, string>;

export type ListStagesAction = Action<ObraActionKeys.LIST_STAGES_REQUEST, number>;
export type ListStagesSuccessAction = Action<ObraActionKeys.LIST_STAGES_SUCCESS, IListStagesResponse>;
export type ListStagesFailureAction = Action<ObraActionKeys.LIST_STAGES_FAILED, string>;

export type CreateStagesAction = Action<ObraActionKeys.CREATE_STAGES_REQUEST, ICreateStageRequest>;
export type CreateStagesSuccessAction = Action<ObraActionKeys.CREATE_STAGES_SUCCESS, ICreateStageResponse>;
export type CreateStagesFailureAction = Action<ObraActionKeys.CREATE_STAGES_FAILED, string>;

export type UpdateStagesAction = Action<ObraActionKeys.UPDATE_STAGES_REQUEST, IUpdateStageRequest>;
export type UpdateStagesSuccessAction = Action<ObraActionKeys.UPDATE_STAGES_SUCCESS, IUpdateStageResponse>;
export type UpdateStagesFailureAction = Action<ObraActionKeys.UPDATE_STAGES_FAILED, string>;

export type DeleteStagesAction = Action<ObraActionKeys.DELETE_STAGES_REQUEST, IDeleteStageRequest>;
export type DeleteStagesSuccessAction = Action<ObraActionKeys.DELETE_STAGES_SUCCESS, IDeleteStageResponse>;
export type DeleteStagesFailureAction = Action<ObraActionKeys.DELETE_STAGES_FAILED, string>;
