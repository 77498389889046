import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ObraActions } from "Store/Obra/Obra.actions";
import { UniqueIdentifier } from "@dnd-kit/core";
import { headerStagesTable, InputStageRequest, IStages } from "./stages.model";
import Button from "Components/UI/Button";
import Item from "./components/Item";
import { Icon } from "Components/UI";
import CreateOrEdit from "./components/CreateOrEdit";
import SimpleDnDListContext from "Components/UI/SimpleDnDListContext";
import SimpleSortableItemWithButton from "Components/UI/SimpleSortableItemWithButton";
import DeleteModal from "./components/Delete";
import Library from "./components/Library";
import EmptyList from "./components/EmptyList";
import PhaseNomenclatura from "./components/PhaseNomenclatura";
import IntegrationDiscipline from "./components/IntegrationDiscipline";
import DisciplineActivities from "./components/DisciplineActivities";
import { ICreateStageRequest, IDeleteStageRequest, IStage, IUpdateStageRequest } from "Data/interfaces/Obra/IObraStages";
import {
  BtnContainer,
  Container,
  Content,
  HeaderContent,
  Main,
  SwitchContainer,
  SwitchStyled,
  Title,
  Warning,
} from "./styles";

const Stages: FC<IStages> = ({
  csId,
  stages,
  disciplines,
  disciplinesData,
  loading,
  obras,
}) => {
  const dispatch = useDispatch();

  const [showEdit, setShowEdit] = useState(false);
  const [sentRequest, setSentRequest] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showLibrary, setShowLibrary] = useState(false);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [stageForEdit, setStageForEdit] = useState<IStage | null>(null);
  const [phasesData, setPhasesData] = useState<IStage[]>(stages);
  const [showPhaseNomenclaturaModal, setShowPhaseNomenclaturaModal] = useState(false);
  const [showIntegrationDisciplinesModal, setShowIntegrationDisciplinesModal] = useState(false);
  const [showDisciplineActivitiesModal, setShowDisciplineActivitiesModal] = useState(false);

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);

  const activeData = useMemo(() => {
    if (activeId && phasesData.length > 0) {
      const active = phasesData.find(phase => phase.TopicStageId === Number(activeId));
      if (active) {
        return active;
      }
    }
    return null;
  }, [activeId, phasesData]);

  const handleSubmit = (values: InputStageRequest) => {
    if (!showEdit || !values) return;

    if (stageForEdit) {
      const request: IUpdateStageRequest = {
        constructionStieId: csId,
        stageId: stageForEdit.TopicStageId,
        stage: {
          constructionSiteDisciplineIds: values.disciplineIds,
        },
      };
      if (values.stageName) request.stage.name = values.stageName;
      if (values.acronym) request.stage.acronym = values.acronym;
      console.log('request handleSubmit UP: ', request);
      dispatch(ObraActions.updateStages(request));
    } else {
      const request: ICreateStageRequest = {
        constructionStieId: csId,
        stage: {
          name: values.stageName,
          acronym: values.acronym,
          constructionSiteDisciplineIds: values.disciplineIds,
        },
      };
      console.log('request handleSubmit C: ', request);
      dispatch(ObraActions.createStages(request));
    }
    setSentRequest(true);
  }

  const handleDelete = (stageId: number) => {
    if (!stageId) return;

    const request: IDeleteStageRequest = {
      constructionStieId: csId,
      stageId,
    };
    dispatch(ObraActions.deleteStages(request));
    setSentRequest(true);
  }

  const openDeleteModal = (phase: IStage) => {
    setStageForEdit(phase);
    setShowDelete(true);
  }

  const openEditModal = (phase: IStage) => {
    setStageForEdit(phase);
    setShowEdit(true);
  }

  const closeAllModal = () => {
    setStageForEdit(null);
    setShowEdit(false);
    setShowPhaseNomenclaturaModal(false);
    setShowIntegrationDisciplinesModal(false);
    setShowDisciplineActivitiesModal(false);
    setShowDelete(false);
  }

  const handleSetNewData = useCallback((
    start: IStage,
    end: IStage,
    data: IStage[],
  ) => {
    if (start.TopicStageId && end.TopicStageId) {
      setPhasesData(data);
    }
  }, []);

  useEffect(() => {
    dispatch(ObraActions.listStages(csId));
  }, []);

  useEffect(() => {
    if (stages) {
      setPhasesData(stages);
    }
  }, [stages]);

  useEffect(() => {
    if (sentRequest && !loading) {
      setSentRequest(false);
      setShowEdit(false);
      closeAllModal();
    }
  }, [sentRequest, loading]);

  return (
    <>
      <Container>
        <Title>Etapas</Title>
        <Main>
          <Content>
            {phasesData.length > 0 && (
              <>
                <HeaderContent>
                  {headerStagesTable.map(head => (
                    <div key={`h-p-t-${head.id}`} className={`column${head.id}`}>
                      <span className="columnText">{head.label}</span>
                    </div>
                  ))}
                </HeaderContent>
                <SimpleDnDListContext
                  data={phasesData.map(phase => ({ ...phase, id: phase.TopicStageId.toString() }))}
                  dragOverlay={activeId && activeData ? (
                    <SimpleSortableItemWithButton
                      key={activeData.TopicStageId}
                      id={activeData.TopicStageId.toString()}
                      activeId={activeId}
                      dragClassName="customDragRow"
                      btnClassName="customRowBtn"
                    >
                      <Item
                        key={`d-p-f-${activeData.TopicStageId}`}
                        {...activeData}
                        disciplines={[]}
                        onUpdate={() => undefined}
                        onRemove={() => undefined}
                      />
                    </SimpleSortableItemWithButton>
                  ) : null}
                  onActiveId={setActiveId}
                  onSetNewData={handleSetNewData}
                >
                  {phasesData.map((phase) => (
                    <SimpleSortableItemWithButton
                      key={phase.TopicStageId.toString()}
                      id={phase.TopicStageId.toString()}
                      className="customRow"
                      btnClassName="customRowBtn"
                      btnShowInHover
                      btnShowIsAbsolute
                    >
                      <Item
                        key={`d-p-f-${phase.TopicStageId}`}
                        {...phase}
                        disciplines={[]}
                        onUpdate={openEditModal}
                        onRemove={openDeleteModal}
                      />
                    </SimpleSortableItemWithButton>
                  ))}
                </SimpleDnDListContext>
              </>
            )}
            {phasesData.length === 0 && (
              <EmptyList
                onEdit={() => setShowEdit(true)}
                onLib={() => setShowLibrary(true)}
              />
            )}
          </Content>
        </Main>

        {phasesData.length > 0 && (
          <BtnContainer>
            <Button
              type="text"
              className="addBtn"
              onClick={() => setShowEdit(true)}
            >
              <Icon
                icon="adicionar"
                customSize={14}
                className="actionIcon"
              />
              <span className="textBtn">Criar nova etapa</span>
            </Button>
            <Button
              type="text"
              className="libBtn"
              onClick={() => setShowLibrary(true)}
            >
              <Icon
                icon="listDetailVision"
                customSize={18}
                className="actionIcon"
              />
              <span className="textBtn">Biblioteca de Etapas</span>
            </Button>
          </BtnContainer>
        )}
      </Container>

      <Container>
        <Title>Nomenclatura</Title>
        <Main>
          <Warning>
            A nomenclatura não conta com o campo de Etapa e por isso, não é possível ativar a validação de etapas na nomenclatura.
          </Warning>

          <SwitchContainer>
            <SwitchStyled
              checked={checked1}
              onChange={() => setShowPhaseNomenclaturaModal(true)}
            />
            <div className="textContent">
              <span className="title">
                Validar etapas na nomenclatura
              </span>
              <span className="text">
                Ao enviar um arquivo que tenha a extensão cadastrada na validação de nomenclatura, o campo de Etapa será validado com base nas etapas cadastradas. Não será possível escolher uma etapa que não esteja cadastrada.
              </span>
            </div>
          </SwitchContainer>
          <SwitchContainer>
            <SwitchStyled
              checked={checked2}
              onChange={() => setShowIntegrationDisciplinesModal(true)}
            />
            <div className="textContent">
              <span className="title">
                Validar vinculação de disciplinas na nomenclatura
              </span>
              <span className="text">
                Só será permitido selecionar as etapas que estão vinculadas com a disciplina que está sendo feito o upload.
              </span>
            </div>
          </SwitchContainer>
        </Main>

        <BtnContainer margintop={24}>
          <Button
            type="text"
            className="configBtn"
            onClick={() => undefined}
          >
            <span className="textConfigBtn">Configurar Nomenclatura</span>
            <Icon
              icon="openNewTab"
              customSize={18}
              className="configIcon"
            />
          </Button>
        </BtnContainer>
      </Container>

      <Container>
        <Title>Atividades</Title>
        <SwitchContainer>
          <SwitchStyled
            checked={checked3}
            onChange={() => setShowDisciplineActivitiesModal(true)}
          />
          <div className="textContent">
            <span className="title">
              Validar vinculação de disciplinas nas atividades
            </span>
            <span className="text">
              O campo de etapas só irá mostrar as etapas que estão vinculadas às disciplinas selecionadas no campo Disciplinas da atividade.
            </span>
          </div>
        </SwitchContainer>
      </Container>

      {showEdit && (
        <CreateOrEdit
          visible={showEdit}
          editStage={stageForEdit}
          disciplines={disciplines}
          disciplinesData={disciplinesData}
          loading={loading}
          onSubmit={handleSubmit}
          onCancel={closeAllModal}
        />
      )}
      {showDelete && stageForEdit && (
        <DeleteModal
          visible={showDelete}
          name={stageForEdit.Name}
          stageId={stageForEdit.TopicStageId}
          loading={loading}
          onConfirm={handleDelete}
          onCancel={closeAllModal}
        />
      )}
      {showLibrary && (
        <Library
          visible={showLibrary}
          obras={obras}
          onSubmit={() => undefined}
          onCancel={() => setShowLibrary(false)}
        />
      )}
      {showPhaseNomenclaturaModal && (
        <PhaseNomenclatura
          visible={showPhaseNomenclaturaModal}
          active={checked1}
          loading={loading}
          onConfirm={() => setChecked1(prev => !prev)}
          onCancel={closeAllModal}
        />
      )}
      {showIntegrationDisciplinesModal && (
        <IntegrationDiscipline
          visible={showIntegrationDisciplinesModal}
          active={checked2}
          onConfirm={() => setChecked2(prev => !prev)}
          onCancel={closeAllModal}
        />
      )}
      {showDisciplineActivitiesModal && (
        <DisciplineActivities
          visible={showDisciplineActivitiesModal}
          active={checked3}
          onConfirm={() => setChecked3(prev => !prev)}
          onCancel={closeAllModal}
        />
      )}
    </>
  )
}

export default Stages;
