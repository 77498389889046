import { IDiscipline, IDisciplineData } from "Data/interfaces/Obra/IObraData";
import { IObraResponse } from "Data/interfaces/Obra/IObraListResponse";
import { IStage } from "Data/interfaces/Obra/IObraStages";

export interface InputStageProps {
  acronym: string;
  stageName: string;
}

export interface InputStageRequest {
  acronym: string;
  stageName: string;
  disciplineIds: number[];
}

export interface IStages {
  csId: number;
  stages: IStage[];
  disciplines: IDiscipline[];
  disciplinesData?: IDisciplineData[];
  obras?: IObraResponse[];
  loading?: boolean;
}

export const headerStagesTable = [
  {
    id: '1',
    label: '#',
  },
  {
    id: '2',
    label: 'Sigla',
  },
  {
    id: '3',
    label: 'Etapa',
  },
  {
    id: '4',
    label: 'Disciplinas',
  },
  {
    id: '5',
    label: '',
  },
];
