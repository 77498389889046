import { FC, useMemo, useState } from "react";
import { Icon, IconName } from "Components/UI";
import Modal from "Components/UI/Modal/Modal";
import { IObraResponse } from "Data/interfaces/Obra/IObraListResponse";
import { GetAvatar } from "Utils/generateThumbnail";
import { Checkbox, ConfigProvider } from "antd";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import Button from "Components/UI/Button";
import {
  Body,
  BodyContent,
  Children,
  Container,
  Content,
  Footer,
  HeaderContent,
  Hr,
  Left,
  Main,
  MainContent,
  Section,
  Title,
  Top,
} from "./styles";

const hasTitle = ['coordly', 'abnt1', 'abnt2', 'abnt3'];

interface OptionsDefault {
  title: string;
  children: {
    id: string;
    title: string;
    description: string;
    icon?: IconName;
    avatar?: string;
  }[]
}

interface DataOptsDefault {
  id: string;
  title: string;
  children: {
    id: string;
    sigla: string;
    phase: string;
  }[]
}

interface ILibrary {
  visible: boolean;
  loading?: boolean;
  obras?: IObraResponse[];
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

const Library: FC<ILibrary> = ({
  visible,
  loading,
  obras,
  onSubmit,
  onCancel,
}) => {
  const theme = useSelector(getTheme);

  const [isSubmit, setIsSubmit] = useState(false);
  const [active, setActive] = useState('coordly');
  const [selecteds, setSelecteds] = useState<string[]>([]);
  const notDefault = !hasTitle.includes(active);

  const options = useMemo(() => {
    const data: OptionsDefault[] = [
      {
        title: 'Selecione um padrão de etapas',
        children: [
          {
            id: 'coordly',
            icon: 'coordly',
            title: 'Coordly',
            description: 'Utilize o padrão de etapas criado pela Coordly para os seus projetos.'
          }
        ]
      },
      {
        title: 'Padrões do mercado',
        children: [
          {
            id: 'abnt1',
            icon: 'abnt',
            title: 'ABNT NBR 15.575',
            description: 'Utilize o padrão de etapas da norma brasileira de edificações habitacionais ABNT NBR 15.575.'
          },
          {
            id: 'abnt2',
            icon: 'abnt',
            title: 'ABNT NBR 15.532',
            description: 'Utilize o padrão de etapas da norma brasileira de elaboração de projetos de edificações ABNT NBR 15.532.'
          },
          {
            id: 'abnt3',
            icon: 'abnt',
            title: 'ABNT NBR 16.636-2',
            description: 'Utilize o padrão de etapas da norma brasileira de elaboração e desenvolvimento de serviços técnicos especializados de projetos arquitetônicos e urbanísticos ABNT NBR 16.636-2.'
          },
        ]
      },
    ];

    if (obras && obras.length > 0) {
      data.push({
        title: 'Padrões de obras',
        children: obras.map(obra => ({
          id: obra.ConstructionSiteId.toString(),
          title: obra.Name,
          avatar: obra.ThumbnailImageUrl || obra.OriginalImageUrl,
          description: 'Utilize o padrão de etapa cadastrada na obra.'
        }))
      })
    }

    return data;
  }, [obras]);

  const optionActive = useMemo(() => {
    return options.flatMap(opt => opt.children).find(opt => opt.id === active);
  }, [active, options]);

  const dataLib = useMemo(() => {
    let dataDefault: DataOptsDefault[] = [];

    dataDefault = [
      {
        id: 'opt-1',
        title: 'Estudo preliminar',
        children: [
          { id: 'opt-1-1', sigla: 'LV', phase: 'Levantamento de Dados' },
          { id: 'opt-1-2', sigla: 'PN', phase: 'Programa de Necessidades' },
          { id: 'opt-1-3', sigla: 'EV', phase: 'Estudo de Viabilidade' },
        ]
      },
      {
        id: 'opt-2',
        title: 'Anteprojeto',
        children: [
          { id: 'opt-2-1', sigla: 'EP', phase: 'Estudo Preliminar' },
          { id: 'opt-2-2', sigla: 'AP', phase: 'Anteprojeto' },
          { id: 'opt-2-3', sigla: 'PL', phase: 'Projeto Legal' },
        ]
      },
      {
        id: 'opt-3',
        title: 'Projeto Executivo',
        children: [
          { id: 'opt-3-1', sigla: 'PE', phase: 'Projeto Executivo' },
          { id: 'opt-3-2', sigla: 'PI', phase: 'Projeto de Interiores' },
          { id: 'opt-3-3', sigla: 'PA', phase: 'Projeto de Arquitetura' },
        ]
      },
      {
        id: 'opt-4',
        title: 'Construção',
        children: [
          { id: 'opt-4-1', sigla: 'CO', phase: 'Construção' },
          { id: 'opt-4-2', sigla: 'AC', phase: 'Acompanhamento de Obra' },
          { id: 'opt-4-3', sigla: 'RE', phase: 'Reforma' },
        ]
      },
    ];

    if (notDefault) {
      dataDefault = [
        {
          id: 'opt-1',
          title: '',
          children: [
            { id: 'opt-1-1', sigla: 'LV', phase: 'Levantamento de Dados' },
            { id: 'opt-1-2', sigla: 'PN', phase: 'Programa de Necessidades' },
            { id: 'opt-1-3', sigla: 'EV', phase: 'Estudo de Viabilidade' },
            { id: 'opt-2-1', sigla: 'EP', phase: 'Estudo Preliminar' },
            { id: 'opt-2-2', sigla: 'AP', phase: 'Anteprojeto' },
            { id: 'opt-2-3', sigla: 'PL', phase: 'Projeto Legal' },
            { id: 'opt-3-1', sigla: 'PE', phase: 'Projeto Executivo' },
            { id: 'opt-3-2', sigla: 'PI', phase: 'Projeto de Interiores' },
            { id: 'opt-3-3', sigla: 'PA', phase: 'Projeto de Arquitetura' },
            { id: 'opt-4-1', sigla: 'CO', phase: 'Construção' },
            { id: 'opt-4-2', sigla: 'AC', phase: 'Acompanhamento de Obra' },
            { id: 'opt-4-3', sigla: 'RE', phase: 'Reforma' },
          ]
        },
      ];
    }

    const allOpts: string[] = [];
    for (const item of dataDefault) {
      for (const child of item.children) {
        allOpts.push(child.id);
      }
    }

    return {
      data: dataDefault,
      dataChildIds: allOpts,
      count: allOpts.length,
    };
  }, [notDefault]);

  const handleSend = () => {
    setIsSubmit(true);
  }

  const onCheck = (id: string) => {
    setSelecteds(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      }

      return [...prev, id];
    })
  };

  const onCheckAll = () => {
    setSelecteds(prev => {
      if (prev.length === dataLib.count) {
        return [];
      }

      return dataLib.dataChildIds;
    });
  };

  return (
    <Modal
      visible={visible}
      width={750}
      footerButtons={null}
    >
      <Container>
        <Title>
          <span className="textTitle">Biblioteca de etapas</span>
          <Icon
            icon="cancelar"
            customSize={12}
            className="iconTitle"
            onClick={onCancel}
          />
        </Title>

        <Content>
          <Left>
            {options.map((opt, index) => (
              <Section key={opt.title}>
                <span className="title">{opt.title}</span>
                {opt.children.map(item => (
                  <Children
                    isactive={active === item.id}
                    onClick={() => setActive(item.id)}
                  >
                    {!item.avatar && item.icon && (
                      <Icon
                        icon={item.icon}
                      />
                    )}
                    {!item.icon && item.avatar && (
                      <GetAvatar
                        name={item.title}
                        shape="circle"
                        size={20}
                        src={item.avatar}
                        thumbSrc={item.avatar}
                        thumbType="mid"
                      />
                    )}
                    <span className="titleChildren">{item.title}</span>
                  </Children>
                ))}
                {index !== options.length - 1 && <Hr />}
              </Section>
            ))}
          </Left>

          <Main>
            <Top>
              <span className="title">{optionActive?.title}</span>
              <span className="description">{optionActive?.description}</span>
            </Top>
            <MainContent>
              <ConfigProvider theme={{
                token: {
                  colorBgContainerDisabled: loading ? theme.colors.surface.surfaceOutline : `color-mix(in srgb, ${theme.colors.primary.primaryOutline}, ${theme.colors.surface.surface})`,
                  colorTextDisabled: theme.colors.primary.onPrimary,
                  colorPrimary: theme.colors.primary.primary
                }
              }}>
                <HeaderContent>
                  <span className="checkbox">
                    <Checkbox
                      checked={selecteds.length === dataLib.count} 
                      disabled={loading}
                      onClick={onCheckAll}
                    />
                  </span>
                  <span className="columnSigla">Sigla</span>
                  <span className="columnEtapa">Etapa</span>
                </HeaderContent>
                <Body>
                  {dataLib.data.map(opt => (
                    <BodyContent key={opt.id} notop={notDefault}>
                      {opt.title && <span className="title">{opt.title}</span>}
                      {opt.children.map(child => (
                        <span key={child.id} className="row">
                          <span className="checkbox">
                            <Checkbox
                              checked={selecteds.includes(child.id)}
                              disabled={loading}
                              onClick={() => onCheck(child.id)}
                            />
                          </span>
                          <span className="rightRow">
                            <span className="columnSigla">{child.sigla}</span>
                            <span className="columnEtapa">{child.phase}</span>
                          </span>
                        </span>
                      ))}
                    </BodyContent>
                  ))}
                </Body>
                <Footer>
                  <span className="textLeft">
                    {`${selecteds.length} etapas selecionadas`}
                  </span>
                  <Button 
                    type="primary"
                    className="btnFooter"
                  >
                    Importar Etapas
                  </Button>
                </Footer>
              </ConfigProvider>
            </MainContent>
          </Main>
        </Content>
      </Container>
    </Modal>
  )
}

export default Library;
