import { FC, ReactNode, useEffect, useState } from "react";
import { useFeatureFlag } from "Hooks/useFeatureFlag";
import useWindowSize from "Hooks/useWindowSize";
import Button from "Components/UI/Button";
import AppStorage from "Utils/AppStorage";
import { DrawerContent, DrawerStyled } from "./styles";

import popupImg from "Static/images/mobile-popup-background.png";
import maletaIcon from "Static/images/logo-maleta-collapse.png";
import googleIcon from "Static/icons/google.png";
import { history } from "Store";

const SHOW_MOBILE_POPUP_KEY = 'COORDLY_SHOW_MOBILE_POPUP_KEY';

interface IButtonMobilePopup {
  icon: ReactNode;
  label: string;
  button: ReactNode;
}

interface IMobilePopUp {
  isAuthenticated: boolean;
}

const MobilePopUp: FC<IMobilePopUp> = ({ isAuthenticated }) => {
  const { width, height, orientation } = useWindowSize();

  const googlePlayRedirectFlag = useFeatureFlag('google-play-link');
  const googlePlayRedirectLink = (googlePlayRedirectFlag.enabled && typeof googlePlayRedirectFlag.value === 'string')
    ? googlePlayRedirectFlag.value
    : 'https://play.google.com/store/search?q=maleta%20do%20engenheiro&c=apps';
  const appleStoreRedirectFlag = useFeatureFlag('apple-store-link');
  const appleStoreRedirectLink = (appleStoreRedirectFlag.enabled && typeof appleStoreRedirectFlag.value === 'string')
    ? appleStoreRedirectFlag.value
    : 'https://apps.apple.com/br/app/maleta-do-engenheiro/id1611061856';

  const [open, setOpen] = useState(false);
  const isApple = navigator.userAgent.indexOf('Mac') !== -1 ||
    navigator.userAgent.indexOf('like Mac') !== -1;

  const redirect = (link: string) => {
    AppStorage.SetItem(SHOW_MOBILE_POPUP_KEY, JSON.stringify(true), 'session');
    window.open(link);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    AppStorage.SetItem(SHOW_MOBILE_POPUP_KEY, JSON.stringify(true), 'session');
    setOpen(false);
  };

  useEffect(() => {
    if (
      (history.location.pathname.includes('/login') ||
        (history.location.pathname === '/' && !isAuthenticated)) &&
      ((width < 800 && orientation === 0) ||
        (height < 800 && orientation === 90))
    ) {
      const showPopup = AppStorage.GetItem(SHOW_MOBILE_POPUP_KEY, 'session');

      if (!showPopup) {
        showDrawer();
      }
    }
  }, [height, orientation, width]);

  const content: IButtonMobilePopup[] = [
    {
      icon: <img src={maletaIcon} alt="" />,
      label: 'Maleta',
      button: (
        <Button
          type="primary"
          className="rowBtn"
          onClick={() => isApple
            ? redirect(appleStoreRedirectLink)
            : redirect(googlePlayRedirectLink)
          }
        >
          Baixar
        </Button>
      ),
    },
    {
      icon: <img src={googleIcon} alt="" />,
      label: 'Browser',
      button: (
        <Button
          type="text"
          className="rowBtn"
          onClick={onClose}
        >
          Continuar
        </Button>
      ),
    }
  ];

  return (
    <DrawerStyled
      placement="bottom"
      height={"auto"}
      open={open}
      closable={false}
      maskClosable={false}
      contentWrapperStyle={{
        margin: 'auto',
        maxWidth: 700,
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
      }}
      style={{
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
      }}
    >
      <DrawerContent ismobile={`${width < 500}`}>
        <div className="content">
          <div className="leftContent">
            <img src={popupImg} alt="" width={314} height={192} />
          </div>
          <div className="rightContent">
            <span className="title">
              Tenha uma experiência melhor usando o aplicativo da Maleta.
            </span>
            <span className="description">
              Tenha acesso e gerencie às suas obras e documentos diretamente do seu celular com nosso aplicativo nativo. Baixe agora o aplicativo da Maleta.
            </span>
          </div>
        </div>
        <div className="divider" />
        <div className="footer">
          {content.map(item => (
            <div key={item.label} className="row">
              <div className="rowLeft">
                <span className="rowIcon">{item.icon}</span>
                <span className="rowLabel">{item.label}</span>
              </div>
              {item.button}
            </div>
          ))}
        </div>
      </DrawerContent>
    </DrawerStyled>
  )
}

export default MobilePopUp;
