import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ObraActions } from "Store/Obra/Obra.actions";
import { IUserData } from "Data/interfaces/User/IUserData";
import { ISharedUser } from "Data/interfaces/User/ISharedUser";
import { IInvitedUser } from "Data/interfaces/User/IInvitedUser";
import { IObraPermissionsRequest } from "Data/interfaces/Obra/IObraPermissions";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Button from "Components/UI/Button";
import Item, { IOnChangePermission } from "./components/Item";
import {
  BtnContainer,
  Container,
  Content,
  Description,
  Divider,
  Main,
  Title,
} from "./styles";

export interface IRole {
  value: number;
  description: string;
  label: string;
}

export interface IUpdatedUsers {
  InvitedUsers: IInvitedUser[];
  SharedUsers: ISharedUser[];
}

interface IPermissions {
  csId: number;
  tenantSharedUsers: IUserData[];
  sharedUsers: ISharedUser[];
  invitedUsers: IInvitedUser[];
  loading?: boolean;
  error?: string;
}

const Permissions: FC<IPermissions> = ({
  csId,
  tenantSharedUsers,
  sharedUsers,
  invitedUsers,
  loading,
  error,
}) => {
  const dispatch = useDispatch();

  const firstRender = useRef(true);
  const [showEdit, setShowEdit] = useState(true);
  const [sentRequest, setSentRequest] = useState(false);
  const [InvitedUsers, setInvitedUsers] = useState<IInvitedUser[]>([]);
  const [SharedUsers, setSharedUsers] = useState<ISharedUser[]>([]);

  const changePermission = (values: IOnChangePermission) => {
    if (values.isInvited) {
      setInvitedUsers(prev => {
        const userIndex = prev.findIndex(user => user.Email === values.ID);
        if (userIndex !== -1) {
          prev[userIndex].HasPlottingPermission = values.hasPlottingPermission;
          if (values.privilege) {
            const privIndex = prev[userIndex].Privileges.indexOf(values.privilege);
            if (privIndex !== -1) {
              prev[userIndex].Privileges.splice(privIndex, 1);
            } else {
              prev[userIndex].Privileges.push(values.privilege);
            }
          }
        }
        return [...prev];
      });
    } else {
      setSharedUsers(prev => {
        const userIndex = prev.findIndex(user => {
          return (user.UserFk || user.UserId) === values.ID;
        });
        if (userIndex !== -1) {
          prev[userIndex].HasPlottingPermission = values.hasPlottingPermission;
          if (values.privilege) {
            const privIndex = prev[userIndex].Privileges.indexOf(values.privilege);
            if (privIndex !== -1) {
              prev[userIndex].Privileges.splice(privIndex, 1);
            } else {
              prev[userIndex].Privileges.push(values.privilege);
            }
          }
        }
        return [...prev];
      });
    }
  };

  const getDifference = (
    arr1: IInvitedUser[] | ISharedUser[],
    arr2: IInvitedUser[] | ISharedUser[],
    isInvited: boolean,
  ) => {
    if (isInvited) {
      const typeArr1 = arr1 as IInvitedUser[];
      const typeArr2 = arr2 as IInvitedUser[];
      return typeArr1.filter(user1 => {
        const user2 = typeArr2.find(user => user.Email === user1.Email);
        if (!user2) return false;
        return (
          user1.HasPlottingPermission !== user2.HasPlottingPermission ||
          JSON.stringify(user1.Privileges) !== JSON.stringify(user2.Privileges)
        );
      });
    }
    const typeArr1 = arr1 as ISharedUser[];
    const typeArr2 = arr2 as ISharedUser[];
    return typeArr1.filter(user1 => {
      const user2 = typeArr2.find(user => {
        return (user.UserFk || user.UserId) === (user1.UserFk || user1.UserId);
      });
      if (!user2) return false;
      return (
        user1.HasPlottingPermission !== user2.HasPlottingPermission ||
        JSON.stringify(user1.Privileges) !== JSON.stringify(user2.Privileges)
      );
    });
  };

  const sharedChanged = useMemo(() => {
    return getDifference(
      SharedUsers, sharedUsers || [], false
    ) as ISharedUser[];
  }, [SharedUsers, sharedUsers]);

  const invitedChanged = useMemo(() => {
    return getDifference(
      InvitedUsers, invitedUsers || [], true
    ) as IInvitedUser[];
  }, [InvitedUsers, invitedUsers]);

  const handleRequest = useCallback(async () => {
    const request: IObraPermissionsRequest = {
      constructionStieId: csId,
      permissions: {
        constructionSiteUsersPermission: sharedChanged.map(user => {
          return {
            userId: user.UserFk || user.UserId || '',
            hasPlottingPermission: user.HasPlottingPermission,
            privileges: user.Privileges,
          }
        }),
        constructionSiteInvitedUsersPermission: invitedChanged.map(user => {
          return {
            email: user.Email,
            hasPlottingPermission: user.HasPlottingPermission,
            privileges: user.Privileges
          }
        }),
      }
    };
    console.log(request);
    dispatch(ObraActions.updatePermissionsUsers(request));
    setSentRequest(true);
  }, [csId, invitedChanged, sharedChanged]);

  useEffect(() => {
    if (!firstRender.current && !error) {
      return;
    }

    if (invitedUsers && invitedUsers.length > 0) {
      setInvitedUsers(JSON.parse(JSON.stringify(invitedUsers)));
      firstRender.current = false;
    }
    if (sharedUsers && sharedUsers.length > 0) {
      setSharedUsers(
        JSON.parse(JSON.stringify(sharedUsers.map(user => ({ ...user, isShared: true }))))
      );
      firstRender.current = false;
    }
  }, [invitedUsers, sharedUsers, error]);

  useEffect(() => {
    if (invitedChanged.length > 0 || sharedChanged.length > 0) {
      setShowEdit(true);
    } else {
      setShowEdit(false);
    }
  }, [invitedChanged, sharedChanged]);

  useEffect(() => {
    if (sentRequest && !loading) {
      setSentRequest(false);
      setShowEdit(false);
    }
  }, [sentRequest, loading]);

  return (
    <Container>
      <Title>Permissões</Title>

      <Main>
        <Description>
          Usuários da obra
        </Description>

        <Content>
          {InvitedUsers.length > 0 && (
            InvitedUsers.map(user => (
              <Item
                key={user.Email}
                ID={user.Email}
                isInvited
                username={user.Email}
                privileges={user.Privileges}
                hasPlottingPermission={user.HasPlottingPermission}
                loading={loading}
                onChange={changePermission}
              />
            ))
          )}
          {SharedUsers.length > 0 && (
            SharedUsers.map(user => (
              <Item
                key={user.UserFk}
                ID={user.UserFk || user.UserId || user.User?.Nome || user.User?.Email || ''}
                username={user.User?.Nome || user.User?.Email || ''}
                userAvatar={user.User?.OriginalImageUrl}
                userAvatarThumb={user.User?.ThumbnailImageUrl}
                userAvatarThumbType="small"
                privileges={user.Privileges}
                hasPlottingPermission={user.HasPlottingPermission}
                loading={loading}
                onChange={changePermission}
              />
            ))
          )}
        </Content>
      </Main>

      {showEdit && (
        <>
          <Divider />
          <BtnContainer>
            <Button
              type="primary"
              className="saveBtn"
              onClick={loading ? undefined : handleRequest}
            >
              {loading && (
                <Spin
                  indicator={
                    <LoadingOutlined
                      rev=""
                      color="white"
                      style={{ fontSize: 12, color: 'white', marginRight: 6 }}
                    />
                  }
                />
              )}
              {loading ? 'Salvando...' : 'Salvar alterações'}
            </Button>
            <Button
              type="text"
              className="cancelBtn"
              disabled={loading}
              onClick={() => setShowEdit(false)}
            >
              Cancelar
            </Button>
          </BtnContainer>
        </>
      )}
    </Container>
  )
}

export default Permissions;
