import { ComponentType } from 'react';
import { Switch, Route } from 'react-router';

import ChangeContext from 'Components/ShellHost/ChangeContext';
import Obras from 'Pages/Obras';
import Disciplinas from 'Pages/Disciplinas';
import ListagemArquivos from 'Pages/ListagemArquivos';
import Usuarios from 'Pages/GerenciarUsuarios/Usuarios';
import PapeisPermissoes from 'Pages/GerenciarUsuarios/PapeisPermissoes';
import Nomenclatura from 'Pages/Config/Nomenclatura';
import Plotagens from 'Pages/Plotagens';
import PlotagemArquivos from 'Pages/Plotagens/PlotagemArquivos/PlotagemArquivos';
import PlotagemAReceber from 'Pages/Plotagens/PlotagemAReceber/PlotagemAReceber';
import Extensoes from 'Pages/Config/Extensoes';
import Armazenamento from 'Pages/Config/Armazenamento';
import MinhaConta from 'Pages/MinhaConta';
import Sobre from 'Pages/Sobre';
import Notification from 'Pages/Notification'
import Faturamento from 'Pages/Faturamento';
import TrocarPlanos from 'Pages/Faturamento/TrocarPlanos';
import Planos from 'Pages/Adm/Planos';
import Tenants from 'Pages/Adm/Tenants';
import DadosFaturamento from 'Pages/Faturamento/DadosFaturamento';
import Downgrade from 'Pages/Faturamento/Downgrade';
import FederatedViewer from 'Pages/FederatedViewer';
import Activities from 'Pages/Activities';
import Personalization from 'Pages/Personalization';
import Panels from 'Pages/Panels';
import EditObra from 'Pages/EditObra';

import { IconName } from 'Components/UI';

export interface IRoute {
  path: string;
  component?: ComponentType<any>;
  title?: string;
  icon?: IconName;
  children?: IRoute[];
  isChange?: boolean;
  isPremium?: boolean;
  isPermissionUser?: number[];
  existNotification?: boolean;
}

const allUsers = [1, 3, 4, 5, 6, 7];

export const routes: IRoute[] = [
  {
    path: '/',
    component: Obras,
  },
  {
    path: '/obras',
    title: 'Obras',
    icon: 'menuObras',
    isChange: true,
    component: Obras,
    isPermissionUser: allUsers,
    existNotification: true,
  },
  {
    path: '/gerenciar-usuarios',
    title: 'Gerenciar usuários',
    icon: 'menuGerenciarUsuario',
    isPermissionUser: [1, 3, 7],
    isPremium: true,
    children: [
      {
        path: '/gerenciar-usuarios/usuarios',
        title: 'Usuários da maleta',
        isPremium: true,
        component: Usuarios,
      },
      {
        path: '/gerenciar-usuarios/papeis',
        title: 'Papéis e permissões',
        isPremium: true,
        component: PapeisPermissoes,
      }
    ]
  },
  {
    path: '/config',
    title: 'Configurar serviço',
    icon: 'menuConfiguracoes',
    isPermissionUser: [1, 7],
    children: [
      {
        path: '/config/nomenclatura',
        title: 'Nomenclatura',
        component: Nomenclatura,
      },
      {
        path: '/config/extensoes',
        title: 'Extensões',
        component: Extensoes,
      },
      {
        path: '/config/armazenamento',
        title: 'Armazenamento',
        component: Armazenamento,
      },
      {
        path: '/config/personalization',
        title: 'Personalização',
        component: Personalization,
        isPermissionUser: [1],
      }
    ]
  },
  {
    path: '/obras/detail/:constructionSiteId',
    component: Disciplinas,
  },
  {
    path: '/obras/detail/:constructionSiteId/:isNewConstructionSite',
    component: Disciplinas,
  },
  {
    path: '/obras/filelist/:constructionSiteId/:folderId',
    component: ListagemArquivos,
  },
  {
    path: '/construtora/:tenantId',
    component: ChangeContext,
  },
  {
    path: '/obras/plotagens/:constructionSiteId',
    component: Plotagens,
    isPremium: true,
    isPermissionUser: allUsers,
  },
  {
    path:
      '/obras/plotagens/filelist/:constructionSiteId/:ConstructionSiteDisciplineFk',
    component: PlotagemArquivos,
  },
  {
    path:
      '/obras/plotagens/filelist/receber/:constructionSiteId/:ConstructionSiteDisciplineFk',
    component: PlotagemAReceber,
  },
  {
    path: '/conta',
    component: MinhaConta,
  },
  {
    path: '/sobre',
    component: Sobre,
  },
  {
    path: '/notification',
    component: Notification,
  },
  {
    path: '/faturamento',
    component: Faturamento,
  },
  {
    path: '/faturamento/planos',
    component: TrocarPlanos,
  },
  {
    path: '/faturamento/planos/dadosfaturamento',
    component: DadosFaturamento,
  },
  {
    path: '/faturamento/planos/dadosfaturamento/downgrade',
    component: Downgrade,
  },
  {
    path: '/adm/planos',
    component: Planos,
  },
  {
    path: '/adm/tenants',
    component: Tenants,
  },
  {
    path: '/obras/viewer/detail/:constructionSiteId',
    component: FederatedViewer,
  },
  {
    path: '/obras/activities/detail/:constructionSiteId',
    component: Activities,
  },
  {
    path: '/obras/panels/:constructionSiteId/:panelId',
    component: Panels,
  },
  {
    path: '/obras/edit/:constructionSiteId/:editId',
    component: EditObra,
  },
];

export const MainSwitch = () => (
  <Switch>{createRecursiveRoutes(routes)?.reverse()}</Switch>
);

const createRecursiveRoutes = (routeList?: IRoute[]) =>
  routeList?.map((props) => (
    <Route
      key={`route-menu-${props.path}`}
      path={props.path}
      title={props.title}
      component={props.component}
      children={createRecursiveRoutes(props.children)}
    />
  ));
