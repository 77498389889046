import { FC, useCallback, useMemo } from "react";
import { getDisciplineIcon } from "Utils/ObraUtils";
import { Icon } from "Components/UI";
import { IDisciplineData } from "Data/interfaces/Obra/IObraData";
import Button from "Components/UI/Button";
import Tooltip from "Components/UI/Tooltip";
import { Container, DisciplineContainer } from "./styles";
import { IStage } from "Data/interfaces/Obra/IObraStages";

interface IItem {
  TopicStageId: number;
  Name: string;
  Acronym?: string;
  ConstructionSiteFk: number;
  TenantFk: number;
  Tenant?: any;
  ConstructionSite?: any;
  Topics?: any;
  disciplines: IDisciplineData[];
  onUpdate: (stage: IStage) => void;
  onRemove: (stage: IStage) => void;
}

const Item: FC<IItem> = ({
  TopicStageId,
  Name,
  Acronym,
  ConstructionSiteFk,
  TenantFk,
  disciplines,
  onUpdate,
  onRemove,
}) => {
  const showDisciplines = useMemo(() => {
    const total = disciplines.length;
    let data = disciplines;
    let rest: IDisciplineData[] = [];

    if (total > 9) {
      data = disciplines.slice(0, 10);
      rest = disciplines.slice(9, disciplines.length);
    }

    return {
      data,
      rest,
      total,
    };
  }, [disciplines]);

  const showIcon = useCallback((index: number) => {
    if ((showDisciplines.total < 9) || (index < showDisciplines.data.length - 1)) {
      return true;
    }

    return false;
  }, [showDisciplines]);

  return (
    <Container>
      <div className={`row1`}>
        <span className="rowText">{TopicStageId}</span>
      </div>
      <div className={`row2`}>
        <span className="rowText">{Acronym}</span>
      </div>
      <div className={`row3`}>
        <span className="rowText">{Name}</span>
      </div>
      <div className={`row4`}>
        {showDisciplines.data.map((discipline, index) => (
          <Tooltip
            key={discipline.DisciplineId}
            title={showIcon(index) ? discipline.Name : undefined}
          >
            <DisciplineContainer istext={!showIcon(index)}>
              {showIcon(index) && (
                <Icon
                  icon={getDisciplineIcon(discipline.Name)}
                  customSize={12}
                  className="disciplineIcon"
                />
              )}
              {!showIcon(index) && (
                <span className="textTotal">{`+${showDisciplines.total - 9}`}</span>
              )}
            </DisciplineContainer>
          </Tooltip>
        ))}
      </div>
      <div className={`row5`}>
        <Button
          type="text"
          className="actionBtn"
          onClick={() => onUpdate({
            TopicStageId,
            Name,
            Acronym,
            ConstructionSiteFk,
            TenantFk,
          })}
        >
          <Icon
            icon="editar"
            customSize={12}
            className="actionIcon"
          />
        </Button>
        <Button
          type="text"
          className="actionBtn"
          onClick={() => onRemove({
            TopicStageId,
            Name,
            Acronym,
            ConstructionSiteFk,
            TenantFk,
          })}
        >
          <Icon
            icon="excluir"
            customSize={12}
            className="actionIcon"
          />
        </Button>
      </div>
    </Container >
  )
}

export default Item;
