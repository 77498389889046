import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Icon } from "Components/UI";
import { IDiscipline, IDisciplineData } from "Data/interfaces/Obra/IObraData";
import Button from "Components/UI/Button";
import Modal from "Components/UI/Modal/Modal";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Form from "Components/UI/Form/Form";
import schema from "./stages.schema";
import { InputStageProps, InputStageRequest } from "../../stages.model";
import Field from "Components/UI/Field";
import SearchInput from "Components/UI/SearchInput";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import ItemDiscipline from "../ItemDiscipline";
import {
  BtnContainer,
  Container,
  Content,
  FormContainer,
  Main,
  SearchContainer,
  Title,
} from "./styles";
import { IStage } from "Data/interfaces/Obra/IObraStages";

interface ICreateOrEdit {
  visible: boolean;
  editStage: IStage | null;
  loading?: boolean;
  disciplines?: IDiscipline[];
  disciplinesData?: IDisciplineData[];
  onSubmit: (values: InputStageRequest) => void;
  onCancel: () => void;
}

const CreateOrEdit: FC<ICreateOrEdit> = ({
  visible,
  editStage,
  loading,
  disciplines,
  disciplinesData,
  onSubmit,
  onCancel,
}) => {
  const theme = useSelector(getTheme)

  const [isSubmit, setIsSubmit] = useState(false);
  const [acronym, setAcronym] = useState('');
  const [stageName, setStageName] = useState('');
  const [search, setSearch] = useState('');
  const [disciplinesList, setDisciplinesList] = useState<IDisciplineData[]>([]);
  const disabled = !acronym || !stageName;

  const filteredDisciplines = useMemo(() => {
    if (search && disciplinesList.length > 0) {
      return disciplinesList.filter(discipline => {
        return discipline.Name?.toLowerCase()?.includes(search?.toLowerCase()) ||
          discipline.CustomDispName?.toLowerCase()?.includes(search?.toLowerCase());
      })
    }

    return disciplinesList;
  }, [search, disciplinesList]);

  const handleSend = () => {
    setIsSubmit(true);
  }

  const handleSubmit = (values: InputStageProps, errors?: string[]) => {
    if (!values || errors) return;

    const disciplineIds = disciplinesList
      .filter(discipline => discipline.IsChecked)
      .map(discipline => discipline.DisciplineId);

    const currentValues: InputStageRequest = {
      ...values,
      disciplineIds
    };
    onSubmit(currentValues);
    
    setIsSubmit(false);
  };

  const onCheck = (index: number) => {
    setDisciplinesList(prev => {
      prev[index].IsChecked = !prev[index].IsChecked;
      return [...prev];
    });
  };

  const handleStorageList = useCallback(() => {
    const list: IDisciplineData[] = [];

    if (
      disciplines && disciplines.length > 0 &&
      disciplinesData && disciplinesData.length > 0
    ) {
      for (const discipline of disciplines) {
        const disciplineIndex = disciplinesData.findIndex((disciplineChecked) => {
          return !disciplineChecked.IsCustom &&
            disciplineChecked.DisciplineId === discipline.DisciplineFk;
        });

        if (disciplineIndex !== -1) {
          list.push({
            ...disciplinesData[disciplineIndex],
            IsChecked: false,
            Disabled: false
          });
        } else {
          list.push({
            Name: 'Personalizado',
            CustomDispName: discipline.CustomName,
            DisciplineId: 9,
            Icon: 'menuContexto',
            IsChecked: false,
            IsCustom: true,
            Disabled: false
          });
        }
      }
    }

    setDisciplinesList(list);
  }, [disciplines, disciplinesData]);

  useEffect(() => {
    handleStorageList();
  }, [disciplines, disciplinesData, handleStorageList]);

  return (
    <Modal
      visible={visible}
      width={450}
      footerButtons={(
        <BtnContainer>
          {disabled && (
            <Button type="text" className="actionBtn" disabled>
              Adicionar etapa
            </Button>
          )}
          {!disabled && (
            <Button type="primary" className="actionBtn" onClick={handleSend}>
              {loading && (
                <Spin
                  indicator={
                    <LoadingOutlined
                      rev=""
                      color="white"
                      style={{ fontSize: 12, color: 'white', marginRight: 6 }}
                    />
                  }
                />
              )}
              {loading ? 'Adicionando...' : 'Criar etapa'}
            </Button>
          )}
        </BtnContainer>
      )}
    >
      <Container>
        <Title>
          <span className="textTitle">Criar nova etapa</span>
          <Icon
            icon="cancelar"
            customSize={12}
            className="iconTitle"
            onClick={onCancel}
          />
        </Title>

        <Content>
          <FormContainer>
            <Form
              schema={schema}
              onSubmit={handleSubmit}
              isSubmited={isSubmit}
              initialValues={{ 
                acronym: editStage?.Acronym || '', 
                stageName: editStage?.Name || '',
              }}
            >
              <div className="formFieldsContainer">
                <span className="containerAcronym">
                  <span className="fieldLabel">
                    Sigla <span className="required">*</span>
                  </span>
                  <Field
                    name='acronym'
                    label=''
                    className="fieldAcronym"
                    value={acronym}
                    onCustomChange={setAcronym}
                  />
                </span>
                <span className="containerStageName">
                  <span className="fieldLabel">
                    Nome da Etapa <span className="required">*</span>
                  </span>
                  <Field
                    name='stageName'
                    label=''
                    className="fieldStageName"
                    value={stageName}
                    onCustomChange={setStageName}
                  />
                </span>
              </div>
            </Form>
          </FormContainer>

          <SearchContainer>
            <div className="titleContainer">
              <span className="titleSearch">Disciplinas vinculadas</span>
              <span className="titleBtn">Selecionar todas</span>
            </div>
            <div className="searchBox">
              <SearchInput
                setSearch={setSearch}
                inputPlaceholder="Pesquisar disciplina..."
                style={{
                  backgroundColor: theme.colors.secondary.secondary,
                }}
              />
            </div>
          </SearchContainer>

          <Main>
            {filteredDisciplines.map((discipline, index) => (
              <ItemDiscipline
                key={`item-${index}`}
                name={discipline.CustomDispName ?? discipline.Name}
                checked={discipline.IsChecked}
                loading={loading}
                onCheck={() => onCheck(index)}
              />
            ))}
          </Main>
        </Content>
      </Container>
    </Modal>
  )
}

export default CreateOrEdit;
